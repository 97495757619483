import {
  AccountBalanceWalletOutlined,
  AdjustOutlined,
  AdminPanelSettingsOutlined,
  BarChartOutlined,
  CableOutlined,
  CampaignOutlined,
  CardGiftcardOutlined,
  ChatOutlined,
  ConfirmationNumberOutlined,
  DisplaySettingsOutlined,
  DoNotDisturbAltOutlined,
  EventAvailableOutlined,
  FilterAltOutlined,
  FmdGoodOutlined,
  GppGoodOutlined,
  GroupOutlined,
  HomeOutlined,
  HotelOutlined,
  HowToRegOutlined,
  IntegrationInstructionsOutlined,
  LanguageOutlined,
  LanOutlined,
  ListAltOutlined,
  ListOutlined,
  LocationOnOutlined,
  MultipleStopOutlined,
  NetworkCheckOutlined,
  NewspaperOutlined,
  PendingActionsOutlined,
  PeopleOutlineOutlined,
  PersonOutlined,
  PhoneCallbackOutlined,
  PhoneIphoneOutlined,
  PollOutlined,
  RouterOutlined,
  SecurityOutlined,
  SendOutlined,
  SettingsOutlined,
  ShareOutlined,
  ShowChartOutlined,
  SmsOutlined,
  SupportAgentOutlined,
  TextsmsOutlined,
  VpnKeyOutlined,
  WatchLaterOutlined,
} from "@mui/icons-material";
import React, { useContext, useMemo } from "react";
import { LanguageContext } from "../language/LanguageContext";

const SideBarItems = () => {
  // SideBarItems - PrivateRouteItems ile aynı klasöre almaya çalışırsam texts tekrar çağırdığından component rerender oluyor.
  const { texts } = useContext(LanguageContext);

  const items = useMemo(
    () => [
      {
        icon: <HomeOutlined />,
        url: "/dashboard",
        text: texts.dashboard,
      },
      {
        icon: <FmdGoodOutlined />,
        url: "/my-locations",
        text: texts.my_locations,
      },
      // LOKASYON AYARLARI::start
      {
        icon: <SettingsOutlined />,
        url: "/location-settings",
        text: texts.location_settings,
        subItems: [
          {
            icon: <DisplaySettingsOutlined />,
            url: "/view-settings",
            text: texts.view_settings,
          },
          {
            icon: <PollOutlined />,
            url: "/survey",
            text: texts.survey_operations,
          },
          {
            icon: <CampaignOutlined />,
            url: "/campaign",
            text: texts.campaign_operations,
          },
          {
            icon: <CardGiftcardOutlined />,
            url: "/promotion",
            text: texts.promotion_operations,
          },
          {
            icon: <NetworkCheckOutlined />,
            url: "/default-limitations/0",
            text: texts.default_limitations,
          },
        ],
      },
      // LOKASYON AYARLARI::end

      // KULLANICI İŞLEMLERİ::start
      {
        icon: <GroupOutlined />,
        url: "/user-actions",
        text: texts.user_actions,
        subItems: [
          {
            icon: <RouterOutlined />,
            url: "/hotspot-live",
            text: texts.hotspot_users_live,
          },
          {
            icon: <CableOutlined />,
            url: "/pppoe-live",
            text: texts.pppoe_users_live,
          },
          {
            icon: <PendingActionsOutlined />,
            url: "/pending-approval",
            text: texts.pending_approval,
          },
          {
            icon: <GroupOutlined />,
            url: "/user-group",
            text: texts.user_groups,
          },
          {
            icon: <HowToRegOutlined />,
            url: "/registered-user",
            text: texts.registered_users,
          },
          {
            icon: <ShareOutlined />,
            url: "/integration-users",
            text: texts.integration_users,
          },
          {
            icon: <SmsOutlined />,
            url: "/sms-users",
            text: texts.sms_users,
          },
          {
            icon: <AdminPanelSettingsOutlined />,
            url: "/vpn-user",
            text: texts.vpn_users,
          },
          {
            icon: <ConfirmationNumberOutlined />,
            url: "/coupon",
            text: texts.coupon,
          },
        ],
      },
      // KULLANICI İŞLEMLERİ::end

      // SMS İŞLEMLERİ::start
      {
        icon: <TextsmsOutlined />,
        url: "/sms-actions",
        text: texts.sms_actions,
        subItems: [
          {
            icon: <SendOutlined />,
            url: "/send-bulk-sms",
            text: texts.send_bulk_sms,
          },
          {
            icon: <AccountBalanceWalletOutlined />,
            url: "/sms-balance",
            text: texts.balance,
          },
        ],
      },
      // SMS İŞLEMLERİ::end

      // AĞ AYARLARI::start
      {
        icon: <LanguageOutlined />,
        url: "/network-settings",
        text: texts.network_settings,
        subItems: [
          {
            icon: <VpnKeyOutlined />,
            url: "/vpn",
            text: texts.vpn,
          },
          {
            icon: <GppGoodOutlined />,
            url: "/ipsec-vpn",
            text: texts.ipsec_vpn,
          },
          {
            icon: <MultipleStopOutlined />,
            url: "/port-forwarding",
            text: texts.port_forwarding,
          },
          {
            icon: <MultipleStopOutlined />,
            url: "/static-ip",
            text: texts.static_ip,
          },
          {
            icon: <ListAltOutlined />,
            url: "/ip-list",
            text: texts.ip_list,
          },
          {
            icon: <ListOutlined />,
            url: "/dhcp-lease",
            text: texts.dhcp_lease,
          },
        ],
      },
      // AĞ AYARLARI::end

      // GÜVENLİK AYARLARI::start
      {
        icon: <SecurityOutlined />,
        url: "/security-settings",
        text: texts.security_settings,
        subItems: [
          {
            icon: <AdminPanelSettingsOutlined />,
            url: "/privilege-action",
            text: texts.privilege_action,
          },
          {
            icon: <DoNotDisturbAltOutlined />,
            url: "/forbidden-mac",
            text: texts.forbidden_mac,
          },
          {
            icon: <FilterAltOutlined />,
            url: "/content-filtering",
            text: texts.content_filtering,
          },
        ],
      },
      // GÜVENLİK AYARLARI::end

      // LOKASYON LOGLARI::start
      {
        icon: <BarChartOutlined />,
        url: "/location-logs",
        text: texts.location_logs,
        subItems: [
          {
            icon: <ListOutlined />,
            url: "/dhcp-logs",
            text: texts.dhcp_logs,
          },
          {
            icon: <LanOutlined />,
            url: "/port-logs",
            text: texts.port_logs,
          },
          {
            icon: <AdjustOutlined />,
            url: "/5651-logs",
            text: texts.logs_5651,
          },
          {
            icon: <TextsmsOutlined />,
            url: "/sms-logs",
            text: texts.sms_logs,
          },
        ],
      },
      // LOKASYON LOGLARI::end

      // LOKASYON RAPORLARI::start
      {
        icon: <NewspaperOutlined />,
        url: "/location-reports",
        text: texts.location_reports,
        subItems: [
          {
            icon: <WatchLaterOutlined />,
            url: "/hourly-reports",
            text: texts.hourly_report_detail,
          },
          {
            icon: <PersonOutlined />,
            url: "/daily-reports",
            text: texts.daily_report_detail,
          },
          {
            icon: <PeopleOutlineOutlined />,
            url: "/weekly-reports",
            text: texts.weekly_report_detail,
          },
          {
            icon: <EventAvailableOutlined />,
            url: "/monthly-reports",
            text: texts.monthly_report_detail,
          },
        ],
      },
      // LOKASYON RAPORLARI::end

      // LOKASYON İSTATİSTİKLERİ::start
      {
        icon: <ShowChartOutlined />,
        url: "/statistics",
        text: texts.statistics,
        subItems: [
          {
            icon: <LocationOnOutlined />,
            url: "/location-statistics",
            text: texts.location_statistics,
          },
          {
            icon: <PhoneIphoneOutlined />,
            url: "/device-statistics",
            text: texts.device_statistics,
          },
          {
            icon: <PersonOutlined />,
            url: "/user-statistics",
            text: texts.user_statistics,
          },
        ],
      },
      // LOKASYON İSTATİSTİKLERİ::end
      // ÇOKLU YAPILANDIRMA::start
      {
        icon: <SettingsOutlined />,
        url: "/multi-configuration",
        text: texts.multi_configuration,
        subItems: [
          {
            icon: <LocationOnOutlined />,
            url: "/location-configuration",
            text: texts.location_configuration,
          },
        ],
      },
      // ÇOKLU YAPILANDIRMA::end
      // API ENTEGRASYONU::start
      {
        icon: <IntegrationInstructionsOutlined />,
        url: "/api-integration",
        text: texts.api_integration,
        subItems: [
          {
            icon: <HotelOutlined />,
            url: "/hotel-integration",
            text: texts.hotel_integration,
          },
          {
            icon: <ShowChartOutlined />,
            url: "/statistic-integration",
            text: texts.statistic_integration,
          },
          {
            icon: <ConfirmationNumberOutlined />,
            url: "/coupon-integration",
            text: texts.coupon_integration,
          },
        ],
      },
      // CANLI DESTEK::start
      {
        icon: <SupportAgentOutlined />,
        url: "/support",
        text: texts.support,
        subItems: [
          {
            icon: <PhoneCallbackOutlined />,
            url: "/live-support",
            text: texts.live_support,
          },
          {
            icon: <ChatOutlined />,
            url: "/ticket-support",
            text: texts.support,
          },
        ],
      },
      // CANLI DESTEK::end
      {
        icon: <FmdGoodOutlined />,
        url: "/test",
        text: "Test",
      },
      {
        icon: <FmdGoodOutlined />,
        url: "/test2",
        text: "Test2",
      },
    ],
    [texts]
  );

  return items;
};

export default SideBarItems;
