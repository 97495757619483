import { useMemo } from "react";

const PrivateRoutesItems = () => {
  const items = useMemo(
    () => [
      {
        url: "/dashboard",
        component: "Dashboard/Dashboard",
      },
      {
        url: "/my-locations",
        component: "MyLocations/MyLocations",
      },
      {
        url: "/my-locations/edit/:id",
        component: "MyLocations/LocationsEdit",
      },
      /****** LOKASYON İŞLEMLERİ::start ******/
      {
        url: "/location-settings",
        subItems: [
          {
            url: "/view-settings",
            component: "ViewSettings/ViewSettings",
          },
          // Surveys::start
          {
            url: "/survey",
            component: "SurveyOperations/SurveyOperations",
          },
          {
            url: "/survey/create",
            component: "SurveyOperations/SurveyCreate",
          },
          {
            url: "/survey/edit/:id",
            component: "SurveyOperations/SurveyEdit",
          },
          // Surveys::end

          // Campaigns::start
          {
            url: "/campaign",
            component: "CampaignOperations/CampaignOperations",
          },
          {
            url: "/campaign/create",
            component: "CampaignOperations/CampaignCreate",
          },
          {
            url: "/campaign/edit/:id",
            component: "CampaignOperations/CampaignEdit",
          },
          // Campaigns::end

          // Promotions::start
          {
            url: "/promotion",
            component: "PromotionOperations/PromotionOperations",
          },
          {
            url: "/promotion/create",
            component: "PromotionOperations/PromotionCreate",
          },
          {
            url: "/promotion/edit/:id",
            component: "PromotionOperations/PromotionEdit",
          },
          {
            url: "/promotion/detail/:id",
            component: "PromotionOperations/PromotionDetail",
          },
          // Promotions::end

          // Default Limitations::start
          {
            url: "/default-limitations/:id",
            component: "DefaultLimitations/DefaultLimitations",
          },
          // Default Limitations::end
        ],
      },
      /****** LOKASYON İŞLEMLERİ::end ******/

      /****** KULLANICI İŞLEMLERİ::start ******/
      {
        url: "/user-actions",
        subItems: [
          // Hotspot Users::start
          {
            url: "/hotspot-live",
            component: "HotspotUsers/HotspotUsers",
          },
          // Hotspot Users::end

          // Pppoe Users::start
          {
            url: "/pppoe-live",
            component: "PppoeUsers/PppoeUsers",
          },
          // Pppoe Users::end

          // Pending Approval::start
          {
            url: "/pending-approval",
            component: "PendingApproval/PendingApproval",
          },
          // Pending Approval::end

          // User Groups::start
          {
            url: "/user-group",
            component: "UserGroups/UserGroups",
          },
          {
            url: "/user-group/create",
            component: "UserGroups/UserGroupCreate",
          },
          {
            url: "/user-group/edit/:id",
            component: "UserGroups/UserGroupEdit",
          },
          // User Groups::end
          // Registered Users::start
          {
            url: "/registered-user",
            component: "RegisteredUsers/RegisteredUsers",
          },
          {
            url: "/registered-user/create",
            component: "RegisteredUsers/RegisteredUserCreate",
          },
          {
            url: "/registered-user/edit/:id",
            component: "RegisteredUsers/RegisteredUserEdit",
          },
          // Registered Users::end
          // Integration Users::start
          {
            url: "/integration-users",
            component: "IntegrationUsers/IntegrationUsers",
          },
          // Integration Users::end
          // Sms Users::start
          {
            url: "/sms-users",
            component: "SmsUsers/SmsUsers",
          },
          // Sms Users::end
          // Vpn Users::start
          {
            url: "/vpn-user",
            component: "VpnUsers/VpnUsers",
          },
          {
            url: "/vpn-user/create",
            component: "VpnUsers/VpnUserCreate",
          },
          {
            url: "/vpn-user/edit/:id",
            component: "VpnUsers/VpnUserEdit",
          },
          // Vpn Users::end
          // Coupon::start
          {
            url: "/coupon",
            component: "Coupon/Coupon",
          },
          {
            url: "/coupon/create",
            component: "Coupon/CouponCreate",
          },
          {
            url: "/coupon/edit/:id",
            component: "Coupon/CouponEdit",
          },
          // Coupon::end
        ],
      },
      /****** KULLANICI İŞLEMLERİ::end ******/

      /****** SMS İŞLEMLERİ::start ******/
      {
        url: "/sms-actions",
        subItems: [
          // Send Bulk Sms::start
          {
            url: "/send-bulk-sms",
            component: "SendBulkSms/SendBulkSms",
          },
          // Send Bulk Sms::end
          // Sms Balance::start
          {
            url: "/sms-balance",
            component: "Balance/Balance",
          },
          // Sms Balance::end
        ],
      },
      /****** SMS İŞLEMLERİ::end ******/

      /****** AĞ AYARLARI::start ******/
      {
        url: "/network-settings",
        subItems: [
          // Vpn::start
          {
            url: "/vpn",
            component: "Vpn/Vpn",
          },
          // Vpn::end
          // IPSEC Vpn::start
          {
            url: "/ipsec-vpn",
            component: "IpsecVpn/IpsecVpn",
          },
          {
            url: "/ipsec-vpn/create",
            component: "IpsecVpn/IpsecVpnCreate",
          },
          {
            url: "/ipsec-vpn/edit/:id",
            component: "IpsecVpn/IpsecVpnEdit",
          },
          // IPSEC Vpn::end
          // Port Forwarding::start
          {
            url: "/port-forwarding",
            component: "PortForwarding/PortForwarding",
          },
          {
            url: "/port-forwarding/create",
            component: "PortForwarding/PortForwardingCreate",
          },
          {
            url: "/port-forwarding/edit/:id",
            component: "PortForwarding/PortForwardingEdit",
          },
          // Port Forwarding::end
          // Static Ip::start
          {
            url: "/static-ip",
            component: "StaticIp/StaticIp",
          },
          {
            url: "/static-ip/create",
            component: "StaticIp/StaticIpCreate",
          },
          {
            url: "/static-ip/edit/:id",
            component: "StaticIp/StaticIpEdit",
          },
          // Static Ip::end
          // Ip List::start
          {
            url: "/ip-list",
            component: "IpList/IpList",
          },
          {
            url: "/ip-list/create",
            component: "IpList/IpListCreate",
          },
          {
            url: "/ip-list/edit/:id",
            component: "IpList/IpListEdit",
          },
          // Ip List::end
          // Dhcp Lease::start
          {
            url: "/dhcp-lease",
            component: "DhcpLease/DhcpLease",
          },
          // Dhcp Lease::end
        ],
      },
      /****** AĞ AYARLARI::end ******/

      /****** GÜVENLİK AYARLARI::start ******/
      {
        url: "/security-settings",
        subItems: [
          // Privilege Action::start
          {
            url: "/privilege-action",
            component: "PrivilegeAction/PrivilegeAction",
          },
          {
            url: "/privilege-action/create",
            component: "PrivilegeAction/PrivilegeActionCreate",
          },
          {
            url: "/privilege-action/edit/:id",
            component: "PrivilegeAction/PrivilegeActionEdit",
          },
          // Privilege Action::end

          // Forbidden Mac::start
          {
            url: "/forbidden-mac",
            component: "ForbiddenMac/ForbiddenMac",
          },
          {
            url: "/forbidden-mac/create",
            component: "ForbiddenMac/ForbiddenMacCreate",
          },
          {
            url: "/forbidden-mac/edit/:id",
            component: "ForbiddenMac/ForbiddenMacEdit",
          },
          // Forbidden Mac::end

          // Content Filtering::start
          {
            url: "/content-filtering",
            component: "ContentFiltering/ContentFiltering",
          },
          {
            url: "/content-filtering/create",
            component: "ContentFiltering/ContentFilteringCreate",
          },
          {
            url: "/content-filtering/edit/:id",
            component: "ContentFiltering/ContentFilteringEdit",
          },
          // Content Filtering::end
        ],
      },
      /****** GÜVENLİK AYARLARI::end ******/

      /****** LOKASYON KAYITLARI::start ******/
      {
        url: "/location-logs",
        subItems: [
          // Dhcp Logs::start
          {
            url: "/dhcp-logs",
            component: "LocationLogs/DhcpLogs",
          },
          // Dhcp Logs::end
          // Port Logs::start
          {
            url: "/port-logs",
            component: "LocationLogs/PortLogs",
          },
          // Port Logs::end
          // 5651 Logs::start
          {
            url: "/5651-logs",
            component: "LocationLogs/Logs5651",
          },
          // 5651 Logs::end
          // Sms Logs::start
          {
            url: "/sms-logs",
            component: "LocationLogs/SmsLogs",
          },
          // Sms Logs::end
        ],
      },
      /****** LOKASYON KAYITLARI::end ******/

      /****** LOKASYON RAPORLARI::start ******/
      {
        url: "/location-reports",
        subItems: [
          // Hourly Reports::start
          {
            url: "/hourly-reports",
            component: "LocationReports/HourlyReportDetail",
          },
          // Hourly Reports::end
          // Daily Reports::start
          {
            url: "/daily-reports",
            component: "LocationReports/DailyReportDetail",
          },
          // Daily Reports::end
          // Weekly Reports::start
          {
            url: "/weekly-reports",
            component: "LocationReports/WeeklyReportDetail",
          },
          // Weekly Reports::end
          // Monthly Reports::start
          {
            url: "/monthly-reports",
            component: "LocationReports/MonthlyReportDetail",
          },
          // Monthly Reports::end
        ],
      },
      /****** LOKASYON RAPORLARI::end ******/

      /****** STATISTICS::start ******/
      {
        url: "/statistics",
        subItems: [
          // Location Statistics::start
          {
            url: "/location-statistics",
            component: "LocationStatistics/LocationStatistics",
          },
          // Location Statistics::end
          // Device Statistics::start
          {
            url: "/device-statistics",
            component: "LocationStatistics/DeviceStatistics",
          },
          // Device Statistics::end
          // Monthly Statistics::start
          {
            url: "/user-statistics",
            component: "LocationStatistics/UserStatistics",
          },
          // Monthly Statistics::end
        ],
      },
      /****** MULTI CONFIGURATION::start ******/
      {
        url: "/multi-configuration",
        subItems: [
          // Location Configuration::start
          {
            url: "/location-configuration",
            component: "MultiConfiguration/MultiConfiguration",
          },
          // Location Configuration::end
        ],
      },
      /****** MULTI CONFIGURATION::end ******/
      /****** API INTEGRATION::start ******/
      {
        url: "/api-integration",
        subItems: [
          // Hotel Integration::start
          {
            url: "/hotel-integration",
            component: "ApiIntegration/HotelIntegration",
          },
          // Hotel Integration::end
          // Statistic Integration::start
          {
            url: "/statistic-integration",
            component: "ApiIntegration/StatisticIntegration",
          },
          // Statistic Integration::end
          // Coupon Integration::start
          {
            url: "/coupon-integration",
            component: "ApiIntegration/CouponIntegration",
          },
          // Coupon Integration::end
        ],
      },
      /****** API INTEGRATION::end ******/
      /****** SUPPORT::start ******/
      {
        url: "/support",
        subItems: [
          // Live Support::start
          {
            url: "/live-support",
            component: "LiveSupport/LiveSupport",
          },
          // Live Support::end
          // Ticket Support::start
          {
            url: "/ticket-support",
            component: "Support/Support",
          },
          {
            url: "/ticket-support/:id",
            component: "Support/SupportChat",
          },
          // Ticket Support::end
        ],
      },
      /****** SUPPORT::end ******/
      {
        url: "/test",
        component: "Test/Test",
      },
    ],
    // eslint-disable-next-line
    []
  );

  return items;
};

export default PrivateRoutesItems;
