import React, { FC, ReactNode, Suspense, lazy, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MasterLayout from "../layout/MasterLayout";
import NotFound from "../pages/NotFound";
import TopBarProgress from "react-topbar-progress-indicator";
import PrivateRoutesItems from "../const/PrivateRoutesItems";

const getPage = (filePath: string) => {
  return lazy(() => import("../pages/" + filePath));
};

const SuspensedView: FC<{ children: ReactNode }> = React.memo(
  ({ children }) => {
    TopBarProgress.config({
      barColors: {
        "0": "#3b82f6",
      },
      barThickness: 2,
      shadowBlur: 5,
      shadowColor: "#60a5fa",
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
  }
);

const PrivateRoutes: FC = () => {
  const SurveyReports = lazy(
    () => import("../pages/SurveyOperations/SurveyReports")
  );

  // SideBarItems - PrivateRouteItems ile aynı klasöre almaya çalışırsam texts tekrar çağırdığından component rerender oluyor.
  const pages = PrivateRoutesItems();

  // Flatten the items into a routes array
  const routes = useMemo(() => {
    const tempRoutes: any[] = [];
    pages.forEach((item) => {
      if (item.subItems) {
        item.subItems.forEach((subItem) => {
          tempRoutes.push({
            path: item.url + subItem.url,
            component: getPage(subItem.component),
          });
        });
      } else {
        tempRoutes.push({
          path: item.url,
          component: getPage(item.component),
        });
      }
    });
    return tempRoutes;
  }, [pages]);

  return (
    <Routes>
      <Route
        path="/location-settings/survey-reports"
        element={
          <SuspensedView>
            <SurveyReports />
          </SuspensedView>
        }
      />
      <Route element={<MasterLayout />}>
        {/* Redirect to Home after success login/registration */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />

        {routes.map(({ path, component: Component }) => (
          <Route
            key={path}
            path={path}
            element={
              <SuspensedView>
                <Component />
              </SuspensedView>
            }
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default React.memo(PrivateRoutes);
