import React, { useContext, useState } from "react";
import * as yup from "yup";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import {
  ErrorOutlineOutlined,
  LocalFireDepartment,
  LanguageOutlined,
  DarkModeOutlined,
  LightModeOutlined,
} from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ColorModeContext, tokens } from "../../theme";
import { LanguageContext } from "../../language/LanguageContext";
import HeaderLanguage from "../../layout/topbar/HeaderLanguage";
import CustomInput from "../../components/CustomInput";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { texts } = useContext(LanguageContext);
  const navigate = useNavigate();

  const [isPosting, setIsPosting] = useState(false);
  const [validatorError, setValidatorError] = useState("");

  const validationSchema = yup.object({
    email: yup
      .string()
      .required(texts.required_field)
      .email(texts.email_valid_alert),
  });

  const keyFrames = {
    "0%": {
      transform: "translate(0px,0px) scale(1)",
    },
    "33%": {
      transform: "translate(15%,-25%) scale(1.1)",
    },
    "66%": {
      transform: "translate(-10%,15%) scale(0.9)",
    },
    "100%": {
      transform: "translate(0px,0px) scale(1)",
    },
  };

  // Language menu
  const [languageState, setLanguageState] = useState<null | HTMLElement>(null);
  const openLanguage = Boolean(languageState);
  const idLanguage = openLanguage ? "simple-popper" : undefined;

  const handleClickLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageState(event.currentTarget);
  };
  const handleLanguageClickAway = () => {
    setLanguageState(null);
  };
  // hex to rgba converter
  const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const methods = useForm({
    defaultValues: {
      email: "",
    },
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit } = methods;

  const onSubmitHandler = async (data: any) => {
    setValidatorError("");
    setIsPosting(true);
    try {
      const response = await axios.post(
        "https://bayi.wisnet.com.tr/api/netway-auth/send-verify-code",
        data
      );
      if (response.data.is_success === true) {
        sessionStorage.setItem("mail", data.email);
        toast.success(texts.reset_code_sent);
        navigate("/auth/forgot-password/confirm");
      }

      setIsPosting(false);
    } catch (error) {
      console.error("error", error);
      setIsPosting(false);
    }
  };
  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid
          container
          component="main"
          sx={{
            height: "100vh",
            backgroundColor: hexToRgba(theme.palette.primary.main, 1),
            overflow: "hidden",
          }}
        >
          {/* FORM::start */}
          <Grid
            item
            xs={12}
            md={6}
            component={Paper}
            elevation={24}
            sx={{
              backgroundColor: `${theme.palette.background.default}90`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 3,
              zIndex: 2,
              position: "relative",
              backgroundImage: "none",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: 20,
                top: 20,
                display: "flex",
                gap: 2,
              }}
            >
              {/* Language */}
              <ClickAwayListener onClickAway={handleLanguageClickAway}>
                <Box>
                  <IconButton
                    id="basic-button"
                    onClick={handleClickLanguage}
                    sx={{
                      backgroundColor: "primary.dark",
                    }}
                  >
                    <LanguageOutlined />
                  </IconButton>
                  <HeaderLanguage
                    open={openLanguage}
                    id={idLanguage}
                    anchorEl={languageState}
                    setAnchorEl={setLanguageState}
                  />
                </Box>
              </ClickAwayListener>
              {/* Dark/Light Mode */}
              <IconButton
                sx={{
                  backgroundColor: "primary.dark",
                }}
                onClick={colorMode.toggleColorMode}
              >
                {theme.palette.mode === "dark" ? (
                  <DarkModeOutlined />
                ) : (
                  <LightModeOutlined />
                )}
              </IconButton>
            </Box>
            <Box
              sx={{
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontSize: { xs: 20, sm: 28 },
                  pb: 4,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {texts.welcome_msg}
                <LocalFireDepartment
                  sx={{
                    color: "error.light",
                    mx: 1,
                    fontSize: { xs: 20, sm: 28 },
                  }}
                />
              </Box>
              <Avatar sx={{ mb: 1, backgroundColor: "primary.dark" }}>
                <LockOutlinedIcon
                  sx={{
                    color: "info.main",
                  }}
                />
              </Avatar>
              <Box
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                {texts.did_you_forgot_password}
              </Box>
              <Box
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: "gray",
                  pb: 2,
                }}
              >
                {texts.enter_email_to_reset}
              </Box>

              <Box
                sx={{
                  mt: 1,
                  width: { xs: 270, sm: 300, md: 350, lg: 400, xl: 450 },
                }}
              >
                {validatorError !== "" && (
                  <Box
                    sx={{
                      width: 1,
                      mb: 2,
                    }}
                  >
                    <Alert
                      sx={{
                        mt: 2,
                        backgroundColor: "error.dark",
                      }}
                      severity="error"
                      iconMapping={{
                        error: (
                          <ErrorOutlineOutlined
                            fontSize="inherit"
                            sx={{
                              color: "error.light",
                            }}
                          />
                        ),
                      }}
                      variant="outlined"
                    >
                      {validatorError}
                    </Alert>
                  </Box>
                )}
                <CustomInput
                  label={texts.email}
                  name="email"
                  required={true}
                  shrink={true}
                />

                <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    justifyContent: "center",
                    py: 2,
                    gap: 1,
                  }}
                >
                  <Button disabled={isPosting} type="submit">
                    {isPosting ? (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: colors.slate[400],
                        }}
                      />
                    ) : (
                      texts.send_link
                    )}
                  </Button>
                  <Button
                    disabled={isPosting}
                    onClick={() => navigate("/auth")}
                  >
                    {texts.cancel}
                  </Button>
                </Box>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  sx={{ mt: 5 }}
                >
                  {"Copyright © "}
                  <Link
                    target="_blank"
                    referrerPolicy="no-referrer"
                    color="inherit"
                    href="https://wisnet.com.tr"
                  >
                    Wisnet
                  </Link>{" "}
                  {new Date().getFullYear()}
                  {"."}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* SIDE::start */}
          <Grid
            item
            xs={false}
            sm={false}
            md={6}
            sx={{
              display: "flex",
              position: "relative",
            }}
          >
            {/* BLURRED OBJECT BACKGROUND::start */}
            <Box
              sx={{
                height: "16rem",
                width: "16rem",
                position: "absolute",
                backgroundColor: colors.orange[800],
                opacity: "70%",
                borderRadius: 100,
                top: "35%",
                right: "50%",
                filter: "blur(24px)",
                mixBlendMode:
                  theme.palette.mode === "dark" ? "color-dodge" : "multiply",
                "@keyframes blob": keyFrames,
                animation: "blob 7s infinite",
                animationDelay: "0s",
              }}
            />
            <Box
              sx={{
                height: "16rem",
                width: "16rem",
                position: "absolute",
                backgroundColor: colors.green[800],
                opacity: "70%",
                borderRadius: 100,
                top: "42%",
                right: "40%",
                filter: "blur(24px)",
                mixBlendMode:
                  theme.palette.mode === "dark" ? "color-dodge" : "multiply",
                "@keyframes blob": keyFrames,
                animation: "blob 7s infinite",
                animationDelay: "2s",
              }}
            />
            <Box
              sx={{
                height: "16rem",
                width: "16rem",
                position: "absolute",
                backgroundColor: colors.fuchsia[800],
                opacity: "70%",
                borderRadius: 100,
                top: "49%",
                right: "30%",
                filter: "blur(24px)",
                mixBlendMode:
                  theme.palette.mode === "dark" ? "color-dodge" : "multiply",
                "@keyframes blob": keyFrames,
                animation: "blob 7s infinite",
                animationDelay: "4s",
              }}
            />
            {/* BLURRED OBJECT BACKGROUND::end */}
            <Box
              sx={{
                height: 1,
                width: 1,
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            ></Box>
          </Grid>
          {/* SIDE::end */}
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ForgotPassword;
