import React, { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import PrivateRoutes from "./PrivateRoutes";
import App from "../App";
import Login from "../auth/Login";
import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ForgotPasswordConfirm from "../pages/ForgotPassword/ForgotPasswordConfirm";

const AppRoutes: FC = React.memo(() => {
  const { user } = useAuth();
  const [theme, colorMode]: any = useMode();
  // RTL için
  // React.useLayoutEffect(() => {
  //   document.body.setAttribute("dir", "rtl");
  // }, []);
  return (
    <BrowserRouter basename="">
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles
            styles={{
              "@keyframes mui-auto-fill": { from: { display: "block" } },
              "@keyframes mui-auto-fill-cancel": { from: { display: "block" } },
            }}
          />
          <Routes>
            <Route element={<App />}>
              {user ? (
                <>
                  <Route path="/*" element={<PrivateRoutes />} />
                  <Route index element={<Navigate to="/dashboard" />} />
                </>
              ) : (
                <>
                  <Route path="auth/*" element={<Login />} />
                  <Route path="*" element={<Navigate to="/auth" />} />
                  <Route
                    path="auth/forgot-password"
                    element={<ForgotPassword />}
                  />
                  <Route
                    path="auth/forgot-password/confirm"
                    element={<ForgotPasswordConfirm />}
                  />
                </>
              )}
            </Route>
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </BrowserRouter>
  );
});

export { AppRoutes };
